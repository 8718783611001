import config, { ConfigEnv } from '../config';

import Axios from 'axios';

const getHeaders = (env: ConfigEnv) => ({
  Authorization: `Bearer ${config(env).wompi.public_key}`,
});

export interface PreSignedAcceptance {
  acceptance_token: string; // JWT
  permalink: string; // URL
  type: string;
}

interface PreApprovalResponseData {
  id: number;
  name: string;
  email: string;
  contact_name: string;
  phone_number: string;
  active: true;
  logo_url: null;
  legal_name: string;
  legal_id_type: string;
  legal_id: string;
  public_key: string;
  accepted_currencies: string[];
  fraud_javascript_key: string;
  accepted_payment_methods: any[];
  presigned_acceptance: PreSignedAcceptance;
}

export interface PreApprovalResponse {
  data: PreApprovalResponseData;
}

export interface TokenizedCardData {
  id: string; //  "tok_prod_15_44c5638281if67l04eA63f705bfA5bde"
  created_at: string; // "2020-09-07T19:09:31.585+00:00"
  brand: string; // "VISA"
  name: string; // "VISA-4242"
  last_four: string; //"4242"
  bin: string; // "538696"
  exp_year: string; // "29"
  exp_month: string; // "06"
  card_holder: string; // "Pedro Pérez"
  expires_at: string; // "2021-09-05T19:09:30.000Z"
}

export interface TokenizedCardResponse {
  status: string; // "CREATED"
  data: TokenizedCardData;
}

interface TransactionResponseData {
  id: string;
  created_at: string;
  amount_in_cents: string;
  status: string;
  reference: string;
  customer_email: string;
  currency: string;
  payment_method_type: string;
  payment_method: {
    type: string;
    phone_number: number;
  };
  shipping_address: {
    address_line_1: string;
    country: string;
    region: string;
    city: string;
    phone_number: number;
  };
  redirect_url: string;
  payment_link_id: null;
}

export interface TransactionResponse {
  data: TransactionResponseData;
}

interface TokenizeCardCall {
  cardNumber: string;
  cardHolder: string;
  cardMonth: string;
  cardYear: string;
  cvv: string;
}

class Wompi {
  static async getPreApproval(env: ConfigEnv): Promise<PreApprovalResponse> {
    const response = await Axios.get<PreApprovalResponse>(
      `${config(env).wompi.url}/merchants/${config(env).wompi.public_key}`
    );
    return response.data;
  }

  static async tokenizeCard(
    { cardNumber, cardMonth, cardYear, cardHolder, cvv }: TokenizeCardCall,
    env: ConfigEnv
  ): Promise<TokenizedCardResponse> {
    const data = {
      number: cardNumber,
      exp_month: cardMonth,
      exp_year: cardYear,
      cvc: cvv,
      card_holder: cardHolder,
    };
    const response = await Axios.post<TokenizedCardResponse>(
      `${config(env).wompi.url}/tokens/cards`,
      data,
      {
        headers: getHeaders(env),
      }
    );
    return response.data;
  }
}

export default Wompi;
