import { call, takeEvery } from 'redux-saga/effects';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { PayloadAction } from '@reduxjs/toolkit';

function* sendToFirebaseSaga({ type: actionType, payload }: PayloadAction) {
  const analytics = getAnalytics();
  if (actionType.startsWith('donate/')) {
    yield call(() => logEvent(analytics, `r_${actionType}`));
  }
}

function* analyticsSaga() {
  yield takeEvery('*', sendToFirebaseSaga);
}

export default analyticsSaga;
