import {
  CallEffect,
  PutEffect,
  all,
  call,
  put,
  takeEvery,
} from '@redux-saga/core/effects';
import {
  Transaction,
  loadTransactions,
  loadTransactionsError,
  loadTransactionsSuccess,
} from '../slices/transactions';

import { PayloadAction } from '@reduxjs/toolkit';
import Storage from '../../apis/storage';

function* loadTransactionsSaga({
  payload: membershipId,
}: PayloadAction<string>): Generator<
  CallEffect<Transaction[]> | PutEffect,
  void,
  Transaction[]
> {
  Storage.init();

  try {
    const transactions = yield call(async () =>
      Storage.getTransactions(membershipId)
    );
    if (transactions.length) {
      yield put(loadTransactionsSuccess(transactions));
    } else {
      yield put(
        loadTransactionsError({
          errorCode: 'no_transactions',
          errorMessage: 'No transactions were loaded',
        })
      );
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    yield put(loadTransactionsError({ errorCode, errorMessage }));
  }
}

function* transactionsSaga() {
  yield all([takeEvery(loadTransactions, loadTransactionsSaga)]);
}

export default transactionsSaga;
