import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PreSignedAcceptance, TokenizedCardData } from '../../apis/wompi';

import { MembershipPeriod } from './memberships';
import { PaymentSource } from '../../apis/storage';

export interface UserData {
  email: string;
  phoneNumber: string;
  fullName: string;
}

export interface CardData {
  cardNumber: string;
  cardMonth: string;
  cardYear: string;
  cvv: string;
  cardHolder: string;
}

export interface DonationData {
  card: CardData;
  amount: number;
}

interface TokenizedCardError {
  type: 'INPUT_VALIDATION_ERROR' | 'UNKOWN_ERROR';
  messages?: {
    [propiedad_invalida: string]: string[]; // Human readable messages
  };
}

export enum Steps {
  start = 0,
  loadingPreApproval,
  preApprovalSuccess,
  preApprovalError,
  creatingTokenizedCard,
  tokenziedCardCreated,
  errorTokenizingCard,
  savingTokenizedCard,
  tokenizedCardSaved,
  errorSavingTokenizedCard,
  creatingMembership,
  membershipCreated,
  errorCreatingMembership,
}

interface DonateState {
  step: Steps;

  amount: number | null;
  period: MembershipPeriod;
  card: CardData | null;

  approval: PreSignedAcceptance | null;
  approvalError: string;

  tokenizedCard: TokenizedCardData | null;
  tokenizedCardError: TokenizedCardError | null;
  firestoreTokenizedCardId: string | null;

  user: UserData | null;
  userError: string;

  paymentSource: PaymentSource | null;

  completionError: any;
}

const initialState: DonateState = {
  step: Steps.start,

  amount: null,
  period: MembershipPeriod.MONTHLY,
  card: null,
  approval: null,
  approvalError: '',

  tokenizedCard: null,
  tokenizedCardError: null,
  firestoreTokenizedCardId: null,

  user: null,
  userError: '',

  paymentSource: null,

  completionError: null,
};

const donateSlice = createSlice({
  name: 'donate',
  initialState,
  reducers: {
    returnToStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
    loadPreApproval(state) {
      state.step = Steps.loadingPreApproval;
      state.approvalError = '';
    },
    loadPreApprovalSuccess(state, action: PayloadAction<PreSignedAcceptance>) {
      state.step = Steps.preApprovalSuccess;
      state.approval = action.payload;
    },
    loadPreApprovalError(state) {
      state.step = Steps.preApprovalError;
      state.approvalError = 'There was an error connecting to wompi';
    },
    tokenizeCard(state, action: PayloadAction<DonationData>) {
      const { card, amount } = action.payload;
      state.amount = amount;
      state.card = card;
      state.step = Steps.creatingTokenizedCard;
      state.tokenizedCardError = null;
    },
    tokenizeCardSuccess(state, action: PayloadAction<TokenizedCardData>) {
      state.step = Steps.tokenziedCardCreated;
      state.tokenizedCard = action.payload;
    },
    tokenizeCardError(state, action: PayloadAction<TokenizedCardError | null>) {
      state.step = Steps.errorTokenizingCard;
      if (action.payload === null) {
        state.tokenizedCardError = {
          type: 'UNKOWN_ERROR',
          general: ['Hubo un error registrando la tarjeta.'],
        } as TokenizedCardError;
      } else if (action.payload.messages !== undefined) {
        state.tokenizedCardError = action.payload;
      } else {
        state.tokenizedCardError = {
          type: 'UNKOWN_ERROR',
          general: ['Error de Wompi'],
        } as TokenizedCardError;
      }
    },
    saveTokenizedCard(state, action: PayloadAction<UserData>) {
      state.user = action.payload;
      state.userError = '';
      state.step = Steps.savingTokenizedCard;
    },
    saveTokenizedCardSuccess(
      state,
      action: PayloadAction<{
        cardReference: string;
        paymentSource: PaymentSource;
      }>
    ) {
      state.firestoreTokenizedCardId = action.payload.cardReference;
      state.paymentSource = action.payload.paymentSource;
      state.step = Steps.tokenizedCardSaved;
    },
    saveTokenizedCardError(state, action: PayloadAction<any>) {
      state.step = Steps.errorSavingTokenizedCard;
      state.completionError = action.payload;
    },
    createMembership(state) {
      state.step = Steps.creatingMembership;
    },
    createMembershipSuccess(state, action: PayloadAction<any>) {
      state.step = Steps.membershipCreated;
    },
    createMembershipError(state, action: PayloadAction<any>) {
      state.step = Steps.errorCreatingMembership;
      state.completionError = action.payload;
    },
  },
});

export const {
  returnToStep,
  loadPreApproval,
  loadPreApprovalSuccess,
  loadPreApprovalError,
  tokenizeCard,
  tokenizeCardSuccess,
  tokenizeCardError,
  saveTokenizedCard,
  saveTokenizedCardSuccess,
  saveTokenizedCardError,
  createMembership,
  createMembershipSuccess,
  createMembershipError,
} = donateSlice.actions;
export default donateSlice.reducer;
