import React, { useEffect } from 'react';
import {
  getAdmins,
  getAdminsError,
  getAdminsLoading,
} from '../store/selectors/admin';
import { useDispatch, useSelector } from 'react-redux';

import { authenticateSignOut } from '../store/slices/auth';
import { getIsAdmin } from '../store/selectors/auth';
import { loadAdmins } from '../store/slices/admin';
import logo from '../assets/logo.svg';

const Header = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const admins = useSelector(getAdmins);

  const loading = useSelector(getAdminsLoading);
  const error = useSelector(getAdminsError);

  const handleSignOut = () => {
    dispatch(authenticateSignOut());
  };
  useEffect(() => {
    if (admins.length === 0 && !loading && !error) {
      dispatch(loadAdmins());
    }
  });

  if (error) {
    const { errorMessage } = error;
    return <div>{errorMessage}</div>;
  }

  return (
    <>
      {isAdmin && (
        <div className="admin-header">
          <ul>
            <li>
              <a href="/">portal de socios</a>
            </li>
            <li>
              <a href="/sandbox">modo de prueba</a>
            </li>
            <li>
              <a href="/login">perfil</a>
            </li>
            <li>
              <a href="/dashboard">estado</a>
            </li>
            <li>
              <a href="/memberships">membresias</a>
            </li>
            <li>
              <button onClick={handleSignOut}>salir</button>
            </li>
          </ul>
        </div>
      )}
      <div className="header">
        <div className="header__inner">
          <div className="header__content">
            <img className="header__logo" src={logo} alt="Club 1%" />
            <h2 className="header__title">
              Renta básica mensual, efectiva y directa.
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
