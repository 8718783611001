import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LoadError {
  errorCode: string;
  errorMessage: string;
}

interface PhotosState {
  photos: string[];
  activePhotoIndex: number | null;
  loading: boolean;
  error: LoadError | null;
}

const initialState: PhotosState = {
  photos: [],
  activePhotoIndex: null,
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    loadPhotos(state, action: PayloadAction<string>) {
      state.photos = [];
      state.activePhotoIndex = null;
      state.error = null;
      state.loading = true;
    },
    loadPhotosSuccess(state, action: PayloadAction<string[]>) {
      state.photos = action.payload;
      if (action.payload.length) {
        state.activePhotoIndex = 0;
      }
      state.error = null;
      state.loading = false;
    },
    loadPhotosError(state, action: PayloadAction<LoadError>) {
      state.photos = [];
      state.activePhotoIndex = null;
      state.error = action.payload;
      state.loading = false;
    },
    startSlideshow(state) {},
    nextPhoto(state) {
      if (state.photos.length && state.activePhotoIndex !== null) {
        state.activePhotoIndex =
          (state.activePhotoIndex + 1) % state.photos.length;
      }
    },
  },
});

export const {
  loadPhotos,
  loadPhotosSuccess,
  loadPhotosError,
  startSlideshow,
  nextPhoto,
} = adminSlice.actions;

export default adminSlice.reducer;
