import React from 'react';
import logo from '../assets/logo.svg';
import packageJson from '../../package.json';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <img className="footer__logo" src={logo} alt="Club 1%" />
        <div className="footer_content">
          <b>CONTACTO</b>
          <a target="_blank" rel="noreferrer" href="https://wa.me/573126545180">
            @Whatsapp
          </a>
          <a href="mailto:club1porciento@gmail.com">club1porciento@gmail.com</a>
          <div className="footer__back_link">
            <a href="https://clubdel1.org">&laquo; volver al sitio principal</a>
          </div>
          <div className="footer__version">v{packageJson.version}</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
