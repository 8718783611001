import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TransactionCustomerData {
  full_name: string;
  phone_number: string;
}

interface TransactionPaymentMethod {
  type: string;
  installments: number;
  extra: {
    bin: string; // "424242"
    brand: string; // "VISA"
    card_holder: string; // "Manuel Zapata S"
    exp_month: string; // "03"
    exp_year: string; // "23"
    last_four: string; // "4242"
    name: string; // "VISA-4242"
  };
}

interface TransactionData {
  payment_method: TransactionPaymentMethod;
  id: string;
  redirect_url: string | null;
  reference: string;
  payment_source_id: number;
  created_at: string;
  customer_email: string;
  taxes: string[];
  shipping_address: string | null;
  currency: string;
  customer_data: TransactionCustomerData;
  status: string;
  amount_in_cents: number;
  payment_method_type: string;
  payment_link_id: string | null;
  status_message: string | null;
  bill_id: string | null;
}

export interface Transaction {
  id: string;
  amount: string;
  transactionData: TransactionData;
  paymentSourceId: string;
  reference: string;
}

export interface TransactionChange {
  id: string;
  change: any;
}

interface LoadError {
  errorCode: string;
  errorMessage: string;
}

interface TransactionsState {
  transactions: Transaction[];
  loading: boolean;
  error: LoadError | null;
}

const initialState: TransactionsState = {
  transactions: [],
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    loadTransactions(state, action: PayloadAction<string>) {
      state.transactions = [];
      state.error = null;
      state.loading = true;
    },
    loadTransactionsSuccess(state, action: PayloadAction<Transaction[]>) {
      state.transactions = action.payload;
      state.error = null;
      state.loading = false;
    },
    loadTransactionsError(state, action: PayloadAction<LoadError>) {
      state.transactions = [];
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  loadTransactions,
  loadTransactionsSuccess,
  loadTransactionsError,
} = adminSlice.actions;

export default adminSlice.reducer;
