import { RootState } from '..';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { getAdmins } from './admin';

export const getUser = (state: RootState) => state.auth.user;

export const getIsAuthenticating = (state: RootState) =>
  state.auth.authenticating;

export const getIsAdmin = createDraftSafeSelector(
  [getUser, getAdmins],
  (user, admins) => {
    if (user == null || admins.length === 0) {
      return false;
    }

    return !!admins.find((admin) => admin.id === user.uid);
  }
);

export const getConfigEnv = (state: RootState) => state.auth.configEnv;
export const getIsSandbox = createDraftSafeSelector(
  [getConfigEnv],
  (env) => env === 'sandbox'
);
