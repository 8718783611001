import { all, fork } from 'redux-saga/effects';

import adminSaga from './admin';
import analyticsSaga from './analytics';
import authSaga from './auth';
import donateSaga from './donate';
import membershipsSaga from './memberships';
import metricsSaga from './metrics';
import navSaga from './nav';
import photosSaga from './photos';
import transactionsSaga from './transactions';

function* rootSaga() {
  yield all([
    fork(navSaga),
    fork(donateSaga),
    fork(authSaga),
    fork(adminSaga),
    fork(membershipsSaga),
    fork(transactionsSaga),
    fork(analyticsSaga),
    fork(photosSaga),
    fork(metricsSaga),
  ]);
}

export default rootSaga;
