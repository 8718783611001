import { History, createBrowserHistory } from 'history';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import adminReducer from './slices/admin';
import authReducer from './slices/auth';
import { createReduxEnhancer } from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import donateReducer from './slices/donate';
import logger from 'redux-logger';
import membershipsReducer from './slices/memberships';
import metricsReducer from './slices/metrics';
import photosReducer from './slices/photos';
import saga from './sagas';
import transactionsReducer from './slices/transactions';

export const history = createBrowserHistory();

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    donate: donateReducer,
    auth: authReducer,
    admin: adminReducer,
    memberships: membershipsReducer,
    transactions: transactionsReducer,
    photos: photosReducer,
    metrics: metricsReducer,
  });

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = createReduxEnhancer({});

const store = configureStore({
  reducer: createRootReducer(history), // root reducer with router state,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false })
      .concat(logger)
      .concat(sagaMiddleware)
      .concat(routerMiddleware(history)), // for dispatching history actions,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer],
});

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
