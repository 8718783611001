import {
  AuthData,
  AuthStart,
  authenticate,
  authenticateError,
  authenticateSignOut,
  authenticateSuccess,
} from '../slices/auth';
import {
  CallEffect,
  PutEffect,
  TakeEffect,
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
} from '@redux-saga/core/effects';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { configureScope, setUser } from '@sentry/browser';

import { PayloadAction } from '@reduxjs/toolkit';
import Storage from '../../apis/storage';
import { User } from 'firebase/auth';
import { eventChannel } from 'redux-saga';

function* authenticateAnonymouslySaga(): Generator<
  CallEffect<User> | PutEffect,
  void,
  User
> {
  Storage.init();
  try {
    yield call([Storage, 'authenticate']);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    yield put(authenticateError({ errorCode, errorMessage }));
  }
}

function* authenticateWithGoogleSaga(): Generator<
  CallEffect | PutEffect,
  void,
  any
> {
  Storage.init();
  const provider = new GoogleAuthProvider();
  const auth = Storage.getAuth();

  try {
    const result = yield call(signInWithPopup, auth, provider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (!credential) {
      yield put(
        authenticateError({
          errorCode: 'NO_CREDENTIALS',
          errorMessage: 'No credentials.',
        })
      );
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
    console.log(errorCode, errorMessage, email, credential);
    yield put(authenticateError({ errorCode, errorMessage }));
  }
}

function* signOutSaga() {
  Storage.init();
  const auth = Storage.getAuth();
  yield call(() => auth.signOut());
}

function* authenticateSaga({
  payload,
}: PayloadAction<AuthStart>): Generator<CallEffect<void>, void, void> {
  const { provider } = payload;

  if (provider === 'ANONYMOUS') {
    yield call(authenticateAnonymouslySaga);
  } else if (provider === 'GOOGLE') {
    yield call(authenticateWithGoogleSaga);
  } else {
    throw new Error(`Invalid provider '${provider}'`);
  }
}

function authWatcherChannel() {
  return eventChannel((emitter) => {
    Storage.init();
    Storage.getAuth().onAuthStateChanged((user) => {
      if (user) {
        emitter(user);
      } else {
        emitter('NO_USER');
      }
    });
    return () => {};
  });
}

function* watchAuthSaga(): Generator<
  CallEffect | TakeEffect | PutEffect,
  void,
  any
> {
  const chan = yield call(authWatcherChannel);
  try {
    while (true) {
      let user = yield take(chan);
      if (user === 'NO_USER') {
        yield put(authenticateSuccess(null));
      } else {
        yield put(authenticateSuccess(user.toJSON()));
      }
    }
  } finally {
    console.log('watcher terminated');
  }
}

function* sentryIdentifyUserSaga(action: PayloadAction<AuthData | null>) {
  const user = action.payload;
  if (user) {
    yield call(() =>
      setUser({
        id: user.uid,
        email: user.email,
      })
    );
  } else {
    yield call(() => configureScope((scope) => scope.setUser(null)));
  }
}

function* authSaga(): Generator<any, void, void> {
  yield all([
    takeEvery(authenticate, authenticateSaga),
    takeEvery(authenticateSignOut, signOutSaga),
    takeEvery(authenticateSuccess, sentryIdentifyUserSaga),
    fork(watchAuthSaga),
  ]);
}

export default authSaga;
