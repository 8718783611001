import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConfigEnv } from '../../config';

type Provider = 'NONE' | 'ANONYMOUS' | 'GOOGLE';

export interface AuthStart {
  provider: Provider;
}

interface AuthError {
  errorCode: string;
  errorMessage: string;
}

interface ProviderData {
  providerId: string;
}

export interface AuthData {
  uid: string;
  user?: any;
  token?: string;
  photoURL?: string;
  displayName?: string;
  email?: string;
  isAnonymous: boolean;
  lastLoginAt?: string;
  providerData?: ProviderData[];
}

interface AuthState {
  provider: Provider;
  user: AuthData | null;
  error: AuthError | null;
  authenticating: boolean;
  configEnv: ConfigEnv | null;
}

const initialState: AuthState = {
  provider: 'NONE',
  user: null,
  error: null,
  authenticating: false,
  configEnv: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate(state, action: PayloadAction<AuthStart>) {
      state.provider = action.payload.provider;
      state.user = null;
      state.error = null;
      state.authenticating = true;
    },
    authenticateSuccess(state, action: PayloadAction<AuthData | null>) {
      // FIXME: update state.provider according to the user value
      state.user = action.payload;
      state.error = null;
      state.authenticating = false;
    },
    authenticateError(state, action: PayloadAction<AuthError>) {
      state.user = null;
      state.error = action.payload;
      state.authenticating = false;
    },
    authenticateSignOut(state) {
      state.provider = 'NONE';
      state.user = null;
      state.error = null;
      state.authenticating = false;
    },
    setConfigEnv(state, action: PayloadAction<{ sandbox: boolean }>) {
      state.configEnv = action.payload.sandbox ? 'sandbox' : 'production';
    },
  },
});

export const {
  authenticate,
  authenticateSuccess,
  authenticateError,
  authenticateSignOut,
  setConfigEnv,
} = authSlice.actions;

export default authSlice.reducer;
