import {
  DocumentReference,
  addDoc,
  collection,
  doc,
  enableIndexedDbPersistence,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Membership, MembershipPeriod } from '../store/slices/memberships';
import { User, getAuth, signInAnonymously } from 'firebase/auth';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';

import { Admin } from '../store/slices/admin';
import { FIREBASE_CONFIG } from '../config';
import { MetricQuery } from '../store/slices/metrics';
import { TokenizedCardData } from './wompi';
import { Transaction } from '../store/slices/transactions';
import { UserData } from '../store/slices/donate';

export interface PaymentSource {}

export interface SaveTokenizedCardResponse {
  cardReference: string;
  paymentSource: PaymentSource;
}

interface CreateMembershipCall {
  createdBy: string;
  tokenizedCardId: string;
  amount: number;
  paymentSourceId: string;
  acceptanceToken: string;
  userData: UserData;
  sandbox: boolean;
  period: MembershipPeriod;
}

interface CreateMembershipResponse {
  transaction: string;
}

interface FirestoreTokenizedCard {
  sandbox: boolean;
  cardData: { id: string };
  paymentSource: any;
}

const COLLECTIONS = {
  admins: 'admins',
  tokenized_cards: 'tokenized_cards',
  memberships: 'memberships',
  tranasctions: (membershipId: string) =>
    `memberships/${membershipId}/transactions`,
};

class Storage {
  private static firebaseApp: FirebaseApp | null = null;

  static init() {
    if (!Storage.firebaseApp) {
      Storage.firebaseApp = initializeApp(FIREBASE_CONFIG);
    }
  }

  static async getAdmins(): Promise<Admin[]> {
    const db = Storage.getDb();

    const querySnapshot = await getDocs(collection(db, COLLECTIONS.admins));
    const result: Admin[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data() as Admin;
      result.push({ ...data, id: doc.id });
    });
    return result;
  }

  static async saveTokenizedCard(
    createdBy: string,
    userData: UserData,
    cardData: TokenizedCardData,
    acceptanceToken: string,
    sandbox: boolean
  ): Promise<SaveTokenizedCardResponse> {
    const db = Storage.getDb();

    const result = await addDoc(collection(db, COLLECTIONS.tokenized_cards), {
      sandbox,
      createdBy,
      userData,
      cardData,
      acceptanceToken,
    });

    return Storage.watchResponse(result, (data: FirestoreTokenizedCard) => {
      if (data && data.paymentSource) {
        return {
          sandbox: data.sandbox,
          cardReference: data.cardData.id,
          paymentSource: data.paymentSource,
        };
      }
    });
  }

  static async getTransactions(membershipId: string): Promise<Transaction[]> {
    const db = Storage.getDb();

    const querySnapshot = await getDocs(
      collection(db, COLLECTIONS.tranasctions(membershipId))
    );
    const result: Transaction[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data() as Transaction;
      result.push({ ...data, id: doc.id });
    });
    return result;
  }

  static async getMemberships(
    membershipIdFilter?: string
  ): Promise<Membership[]> {
    const db = Storage.getDb();

    if (membershipIdFilter) {
      const snap = await getDoc(
        doc(db, COLLECTIONS.memberships, membershipIdFilter)
      );
      if (!snap) return [];
      const membershipData = snap.data() as Membership;
      return [{ ...membershipData, id: snap.id }];
    }

    const querySnapshot = await getDocs(
      collection(db, COLLECTIONS.memberships)
    );
    const result: Membership[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data() as Membership;
      result.push({ ...data, id: doc.id });
    });
    return result;
  }

  static async createMembership({
    createdBy,
    tokenizedCardId,
    amount,
    paymentSourceId,
    acceptanceToken,
    userData,
    sandbox,
    period,
  }: CreateMembershipCall): Promise<CreateMembershipResponse> {
    const db = Storage.getDb();

    const doc = {
      active: true,
      latestTransactionSeq: 0,
      sandbox,
      createdBy,
      tokenizedCardId,
      amount,
      paymentSourceId,
      acceptanceToken,
      userData,
      period,
    };

    const result = await addDoc(collection(db, 'memberships'), doc);

    return Storage.watchResponse(
      result,
      (data: { transactions: Transaction[] }) => {
        if (data && data.transactions && data.transactions.length) {
          return {
            transaction: data.transactions[0],
          };
        }
      }
    );
  }

  public static async updateMembership(id: string, change: any): Promise<void> {
    const db = Storage.getDb();
    const docRef = doc(collection(db, 'memberships'), id);
    return updateDoc(docRef, change);
  }

  private static async watchResponse(
    ref: DocumentReference,
    callback: (data: any) => any
  ) {
    const readDocPromise = new Promise((resolve) => {
      const interval = setInterval(
        () =>
          getDoc(ref).then((doc) => {
            console.log('Checking...');
            const data = doc.data();
            const response = callback(data);
            if (response) {
              clearInterval(interval);
              resolve(response);
            }
          }),
        1000
      );
    });

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject('Transaction timeout'), 30000);
    });

    return Promise.race<any>([readDocPromise, timeoutPromise]);
  }

  private static getDb() {
    if (Storage.firebaseApp === null) {
      throw new Error('Firebase app no initialized');
    }
    const db = getFirestore(Storage.firebaseApp);
    return db;
  }

  public static async authenticate(): Promise<User> {
    if (Storage.firebaseApp === null) {
      throw new Error('Firebase app no initialized');
    }
    const auth = getAuth(Storage.firebaseApp);
    const creds = await signInAnonymously(auth);
    return creds.user;
  }

  public static getAuth() {
    if (Storage.firebaseApp === null) {
      throw new Error('Firebase app no initialized');
    }
    return getAuth(Storage.firebaseApp);
  }

  public static async getPhotos() {
    if (Storage.firebaseApp === null) {
      throw new Error('Firebase app no initialized');
    }
    const storage = getStorage(Storage.firebaseApp);
    const listRef = ref(storage, 'photos');
    const res = await listAll(listRef);
    return Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
  }

  public static async getMetric(query: MetricQuery) {
    if (query.id === 'REVENUE_30D') {
      const datapoints = [0, 20, 20, 60, 60, 120, 180, 120, 125, 105, 110, 170];
      const labels = [];
      for (let i = 0; i < 30; ++i) {
        labels.push(i.toString());
      }
      return { labels, datapoints, query };
    } else if (query.id === 'REVENUE_LAST1') {
      return { labels: [0, 1], datapoints: [100, 120], query };
    } else if (query.id === 'REVENUE_LAST7') {
      return { labels: [0, 1], datapoints: [1000, 1300], query };
    } else if (query.id === 'REVENUE_LAST28') {
      return { labels: [0, 1], datapoints: [4000, 4120], query };
    } else {
      throw new Error(`Unknown query '${query.id}'`);
    }
  }

  public static async enablePersistance() {
    const db = Storage.getDb();
    try {
      await enableIndexedDbPersistence(db);
      console.log('Firestore persistance enabled');
    } catch (err) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    }
  }
}

export default Storage;
