const WOMPI_BASE_URL_PROD = 'https://production.wompi.co/v1';
const WOMPI_BASE_URL_SANDBOX = 'https://sandbox.wompi.co/v1';
const PUB_KEY_PROD = 'pub_prod_EfZmK5IG4B9DUG2v16AiLnAVc6xIwYrQ';
const PUB_KEY_SANDBOX = 'pub_test_nvKdWHiq1g2YtpPEIUfz0ELr1gbUZkW4';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDJRXbWS5nsfWIgjXjq9KCgmrE6BJQ-Htk',
  authDomain: 'donate-page-f34b6.firebaseapp.com',
  projectId: 'donate-page-f34b6',
  storageBucket: 'donate-page-f34b6.appspot.com',
  messagingSenderId: '735922768355',
  appId: '1:735922768355:web:6921e1737f97d02ba038d6',
  measurementId: 'G-5Q3ZW0CLZG',
};

export type ConfigEnv = 'production' | 'sandbox';

export const SENTRY_DSN =
  'https://a9fd454124df4d3780be27d930285dd0@o692121.ingest.sentry.io/5774645';

const config = (env: ConfigEnv) => {
  if (window.location.hash === '#sandbox') {
    window.location.href = window.location.href.replace('#', '');
  }

  if (env === 'sandbox') {
    return {
      sandbox: true,
      wompi: {
        public_key: PUB_KEY_SANDBOX,
        url: WOMPI_BASE_URL_SANDBOX,
      },
    };
  } else {
    return {
      sandbox: false,
      wompi: {
        public_key: PUB_KEY_PROD,
        url: WOMPI_BASE_URL_PROD,
      },
    };
  }
};

export default config;
