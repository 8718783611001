import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserData } from './donate';

export enum MembershipPeriod {
  MONTHLY = '1m',
}

export interface Membership {
  id: string;
  name: string;
  userData: UserData;
  acceptanceToken: string;
  tokenizedCardId: string;
  amount: number;
  createdBy: string;
  paymentSourceId: string;
  active: boolean;
  sandbox: boolean;
  latestTransaction: string;
  latestTransactionSeq: number;
  nextTransaction: string | null;
  period: MembershipPeriod;
}

export interface MembershipChange {
  id: string;
  change: any;
}

interface LoadError {
  errorCode: string;
  errorMessage: string;
}

interface MembershipsState {
  memberships: Membership[];
  loading: boolean;
  error: LoadError | null;
}

const initialState: MembershipsState = {
  memberships: [],
  loading: false,
  error: null,
};

const membershipsSlice = createSlice({
  name: 'memberships',
  initialState,
  reducers: {
    loadMemberships(state, action: PayloadAction<{ filter?: string }>) {
      state.memberships = [];
      state.error = null;
      state.loading = true;
    },
    loadMembershipsSuccess(state, action: PayloadAction<Membership[]>) {
      state.memberships = action.payload;
      state.error = null;
      state.loading = false;
    },
    loadMembershipsError(state, action: PayloadAction<LoadError>) {
      state.memberships = [];
      state.error = action.payload;
      state.loading = false;
    },
    updateMembership(state, action: PayloadAction<MembershipChange>) {},
  },
});

export const {
  loadMemberships,
  loadMembershipsSuccess,
  loadMembershipsError,
  updateMembership,
} = membershipsSlice.actions;

export default membershipsSlice.reducer;
