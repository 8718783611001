import {
  PutEffect,
  SelectEffect,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { ConfigEnv } from '../../config';
import { PayloadAction } from '@reduxjs/toolkit';
import { getConfigEnv } from '../selectors/auth';
import { setConfigEnv } from '../slices/auth';

interface RouteAction {
  location: {
    pathname: string;
  };
}

function* locationWatcherSaga({
  payload,
}: PayloadAction<RouteAction>): Generator<
  SelectEffect | PutEffect,
  void,
  ConfigEnv
> {
  const configEnv = yield select(getConfigEnv);
  if (!configEnv) {
    const { location } = payload;
    const sandbox = location.pathname === '/sandbox';
    yield put(setConfigEnv({ sandbox }));
  }
}

export default function* navSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', locationWatcherSaga);
}
