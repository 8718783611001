import './styles/app.css';

import store, { history } from './store';

import { ConnectedRouter } from 'connected-react-router';
import Footer from './components/Footer';
import Header from './components/Header';
import { Provider } from 'react-redux';
import React from 'react';
import { Route } from 'react-router';

const App: React.FC = () => {
  const REDIRECT_URL =
    'https://go.suscripciones.co/o/clubdel1/donations?return_to=https://www.clubdel1.org';
  return (
    <Provider store={store}>
      <div className="app">
        <Header />
        <ConnectedRouter history={history}>
          <Route
            path="/"
            component={() => {
              window.location.href = REDIRECT_URL;
              return (
                <h1>
                  Usted esta siendo redirigido a suscripciones.co. Espere un
                  momento o haga <a href={REDIRECT_URL}>click aqui</a>
                </h1>
              );
            }}
          />
        </ConnectedRouter>
        <Footer />
      </div>
    </Provider>
  );
};

export default App;
