import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Admin {
  id: string;
  email: string;
}

interface LoadError {
  errorCode: string;
  errorMessage: string;
}

interface AdminState {
  admins: Admin[];
  adminsError: LoadError | null;
  adminsLoading: boolean;
}

const initialState: AdminState = {
  admins: [],
  adminsError: null,
  adminsLoading: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    loadAdmins(state) {
      state.admins = [];
      state.adminsError = null;
      state.adminsLoading = true;
    },
    loadAdminsSuccess(state, action: PayloadAction<Admin[]>) {
      state.admins = action.payload;
      state.adminsError = null;
      state.adminsLoading = false;
    },
    loadAdminsError(state, action: PayloadAction<LoadError>) {
      state.admins = [];
      state.adminsError = action.payload;
      state.adminsLoading = false;
    },
  },
});

export const { loadAdmins, loadAdminsSuccess, loadAdminsError } =
  adminSlice.actions;

export default adminSlice.reducer;
