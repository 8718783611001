import {
  CallEffect,
  PutEffect,
  all,
  call,
  put,
  take,
  takeEvery,
  takeLeading,
} from '@redux-saga/core/effects';
import {
  loadPhotosError,
  loadPhotosSuccess,
  nextPhoto,
  startSlideshow,
} from '../slices/photos';

import Storage from '../../apis/storage';
import { eventChannel } from 'redux-saga';
import { tokenizeCardSuccess } from '../slices/donate';

function* loadPhotosSaga(): Generator<CallEffect | PutEffect, void, any> {
  Storage.init();
  try {
    const photos = yield call(() => Storage.getPhotos());
    yield put(loadPhotosSuccess(photos));
    yield put(startSlideshow());
  } catch (error) {
    yield put(
      loadPhotosError({
        errorCode: 'error_loading_photos',
        errorMessage: error.toString(),
      })
    );
  }
}

function photoEmitter(interval: number) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter('NEXT_PHOTO');
    }, interval);
    return () => {
      clearInterval(iv);
    };
  });
}

function* startSlideshowSaga(): Generator<any, any, any> {
  const chan = yield call(photoEmitter, 2000);
  try {
    while (true) {
      yield take(chan);
      yield put(nextPhoto());
    }
  } finally {
    console.log('countdown terminated');
  }
}

function* photosSaga() {
  yield all([
    takeEvery(tokenizeCardSuccess, loadPhotosSaga),
    takeLeading(startSlideshow, startSlideshowSaga),
  ]);
}

export default photosSaga;
