import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MetricQuery {
  id: string;
}

export interface Metric {
  id: string;
  uid: string;
  query: MetricQuery;
  labels: number[];
  datapoints: number[];
}

export interface LoadError {
  errorCode: string;
  errorMessage: string;
  query: MetricQuery;
}

interface Metrics {
  [queryId: string]: Metric;
}

interface MetricsLoading {
  [queryId: string]: boolean;
}

interface MetricsErrors {
  [queryId: string]: LoadError;
}

interface MetricsState {
  data: Metrics;
  errors: MetricsErrors;
  loading: MetricsLoading;
}

const initialState: MetricsState = {
  data: {},
  errors: {},
  loading: {},
};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    loadMetric(state, action: PayloadAction<MetricQuery>) {
      const queryId = action.payload.id;
      delete state.data[queryId];
      delete state.errors[queryId];
      state.loading[queryId] = true;
    },
    loadMetricSuccess(state, action: PayloadAction<Metric>) {
      const queryId = action.payload.query.id;
      state.data[queryId] = action.payload;
      delete state.errors[queryId];
      state.loading[queryId] = false;
    },
    loadMetricError(state, action: PayloadAction<LoadError>) {
      const queryId = action.payload.query.id;
      delete state.data[queryId];
      state.errors[queryId] = action.payload;
      state.loading[queryId] = false;
    },
  },
});

export const { loadMetric, loadMetricSuccess, loadMetricError } =
  metricsSlice.actions;

export default metricsSlice.reducer;
