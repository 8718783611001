import {
  CallEffect,
  PutEffect,
  all,
  call,
  put,
  takeEvery,
} from '@redux-saga/core/effects';
import {
  Metric,
  MetricQuery,
  loadMetric,
  loadMetricError,
  loadMetricSuccess,
} from '../slices/metrics';

import { PayloadAction } from '@reduxjs/toolkit';
import Storage from '../../apis/storage';

function* loadMetricSaga({
  payload: query,
}: PayloadAction<MetricQuery>): Generator<
  CallEffect | PutEffect,
  void,
  Metric
> {
  Storage.init();

  try {
    const metric = yield call(async () => Storage.getMetric(query));
    if (metric) {
      yield put(loadMetricSuccess(metric));
    } else {
      yield put(
        loadMetricError({
          query,
          errorCode: 'metric_not_found',
          errorMessage: 'No metrics were loaded',
        })
      );
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    yield put(loadMetricError({ errorCode, errorMessage, query }));
  }
}

function* metricsSaga() {
  yield all([takeEvery(loadMetric, loadMetricSaga)]);
}

export default metricsSaga;
