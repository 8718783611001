import { RootState } from '..';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const getAmount = (state: RootState) => state.donate.amount;
export const getPeriod = (state: RootState) => state.donate.period;
export const getPreApproval = (state: RootState) => state.donate.approval;
export const getPreApprovalError = (state: RootState) =>
  state.donate.approvalError;
export const getCardData = (state: RootState) => state.donate.card;
export const getTokenizedCardData = (state: RootState) =>
  state.donate.tokenizedCard;
export const getTokenizedCardError = (state: RootState) =>
  state.donate.tokenizedCardError;
export const getTokenizedCardRef = (state: RootState) =>
  state.donate.firestoreTokenizedCardId;
export const getUserData = (state: RootState) => state.donate.user;
export const getPaymentSource = (state: RootState) =>
  state.donate.paymentSource;
export const getStep = (state: RootState) => state.donate.step;
export const getTermsLink = (state: RootState) =>
  state.donate.approval?.permalink || '';

export const getUserFirstName = createDraftSafeSelector(
  [getUserData],
  (user) => {
    if (!user) return null;
    const fullName = user.fullName;
    if (!fullName) return null;
    return fullName.split(' ')[0];
  }
);
