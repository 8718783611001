import {
  CallEffect,
  PutEffect,
  all,
  call,
  put,
  takeEvery,
} from '@redux-saga/core/effects';
import {
  Membership,
  MembershipChange,
  loadMemberships,
  loadMembershipsError,
  loadMembershipsSuccess,
  updateMembership,
} from '../slices/memberships';

import { PayloadAction } from '@reduxjs/toolkit';
import Storage from '../../apis/storage';
import { loadTransactions } from '../slices/transactions';

function* loadMembershipsSaga({
  payload,
}: PayloadAction<{ filter?: string }>): Generator<
  CallEffect<Membership[]> | PutEffect,
  void,
  Membership[]
> {
  const { filter } = payload || {};
  Storage.init();
  try {
    const memberships = yield call(async () => Storage.getMemberships(filter));
    if (memberships.length) {
      yield put(loadMembershipsSuccess(memberships));
    } else {
      yield put(
        loadMembershipsError({
          errorCode: 'no_memberships',
          errorMessage: 'No memberships were loaded',
        })
      );
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    yield put(loadMembershipsError({ errorCode, errorMessage }));
  }
}

function* updateMembershipSaga({ payload }: PayloadAction<MembershipChange>) {
  const { id, change } = payload;
  try {
    yield call(() => Storage.updateMembership(id, change));
    yield put(loadMemberships({}));
  } catch (e) {
    console.log('Error updating membership');
  }
}

function* loadMembershipForTransactionsSaga({
  payload: membershipId,
}: PayloadAction<string>) {
  yield put(loadMemberships({ filter: membershipId }));
}

function* membershipsSaga() {
  yield all([
    takeEvery(loadMemberships, loadMembershipsSaga),
    takeEvery(updateMembership, updateMembershipSaga),
    takeEvery(loadTransactions, loadMembershipForTransactionsSaga),
  ]);
}

export default membershipsSaga;
