import {
  Admin,
  loadAdmins,
  loadAdminsError,
  loadAdminsSuccess,
} from '../slices/admin';
import {
  CallEffect,
  PutEffect,
  all,
  call,
  put,
  takeEvery,
} from '@redux-saga/core/effects';

import Storage from '../../apis/storage';

function* loadAdminsSaga(): Generator<CallEffect | PutEffect, void, Admin[]> {
  Storage.init();

  yield call(() => Storage.enablePersistance());

  try {
    const admins = yield call(async () => Storage.getAdmins());
    if (admins.length) {
      yield put(loadAdminsSuccess(admins));
    } else {
      yield put(
        loadAdminsError({
          errorCode: 'no_admins',
          errorMessage: 'No admins were loaded',
        })
      );
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    yield put(loadAdminsError({ errorCode, errorMessage }));
  }
}

function* adminSaga() {
  yield all([takeEvery(loadAdmins, loadAdminsSaga)]);
}

export default adminSaga;
