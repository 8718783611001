import {
  CallEffect,
  PutEffect,
  SelectEffect,
  all,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import {
  CardData,
  UserData,
  createMembership,
  createMembershipError,
  createMembershipSuccess,
  loadPreApproval,
  loadPreApprovalError,
  loadPreApprovalSuccess,
  saveTokenizedCard,
  saveTokenizedCardError,
  saveTokenizedCardSuccess,
  tokenizeCard,
  tokenizeCardError,
  tokenizeCardSuccess,
} from '../slices/donate';
import Wompi, {
  PreApprovalResponse,
  TokenizedCardData,
  TokenizedCardResponse,
} from '../../apis/wompi';
import { call, put } from 'redux-saga/effects';
import {
  getAmount,
  getCardData,
  getPaymentSource,
  getPeriod,
  getPreApproval,
  getTokenizedCardData,
  getTokenizedCardRef,
  getUserData,
} from '../selectors/donate';
import { getConfigEnv, getIsSandbox, getUser } from '../selectors/auth';

import { ConfigEnv } from '../../config';
import Storage from '../../apis/storage';

function* loadPreApprovalSaga(): Generator<
  CallEffect<PreApprovalResponse> | PutEffect | SelectEffect,
  void,
  any
> {
  try {
    const configEnv = yield select(getConfigEnv);
    const result = yield call(() => Wompi.getPreApproval(configEnv));
    yield put(loadPreApprovalSuccess(result.data.presigned_acceptance));
  } catch (e) {
    yield put(loadPreApprovalError());
  }
}

function* tokenizeCardSaga(): Generator<
  CallEffect<TokenizedCardResponse> | PutEffect | SelectEffect,
  void,
  any
> {
  const configEnv: ConfigEnv = yield select(getConfigEnv);
  const data: CardData = yield select(getCardData);
  try {
    const result = yield call(() => Wompi.tokenizeCard(data, configEnv));
    yield put(tokenizeCardSuccess(result.data));
  } catch (e) {
    if (e.response.status === 422) {
      yield put(tokenizeCardError(e.response.data.error));
    } else {
      yield put(tokenizeCardError(null));
    }
  }
}

function* saveTokenizedCardSaga(): Generator<
  CallEffect | SelectEffect | PutEffect,
  void,
  any
> {
  const isSandbox = yield select(getIsSandbox);

  Storage.init();

  const cardData: TokenizedCardData = yield select(getTokenizedCardData);
  const userData: UserData = yield select(getUserData);
  const { acceptance_token } = yield select(getPreApproval);
  const { uid } = yield select(getUser);

  try {
    const { cardReference, paymentSource } = yield call(() =>
      Storage.saveTokenizedCard(
        uid,
        userData,
        cardData,
        acceptance_token,
        isSandbox
      )
    );
    yield put(saveTokenizedCardSuccess({ cardReference, paymentSource }));
  } catch (err) {
    yield put(saveTokenizedCardError(err));
    return;
  }

  const amount = yield select(getAmount);
  const period = yield select(getPeriod);
  const paymentSource = yield select(getPaymentSource);
  const tokenizedCardId = yield select(getTokenizedCardRef);

  yield put(createMembership());

  try {
    const membership = yield call(() =>
      Storage.createMembership({
        createdBy: uid,
        tokenizedCardId,
        amount,
        paymentSourceId: paymentSource.id,
        acceptanceToken: acceptance_token,
        userData,
        sandbox: isSandbox,
        period,
      })
    );
    yield put(createMembershipSuccess(membership));
  } catch (e) {
    yield put(createMembershipError(e.toString()));
  }
}

function* donateSaga() {
  yield all([
    takeEvery(tokenizeCard, tokenizeCardSaga),
    takeEvery(loadPreApproval, loadPreApprovalSaga),
    takeEvery(saveTokenizedCard, saveTokenizedCardSaga),
  ]);
}

export default donateSaga;
